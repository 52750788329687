export const WORDS = [
  'splendor',
  'clank',
  'paris',
  'dixit',
  'jaipur',
  'catan',
  'fresco',
  'activity',
  'wingspan',
  'gloomhaven',
  'cascadia',
  'takenoko',
  'alhambra',
  'calico',
  'nidavellir',
  'tzolkin',
  'quacks',
  'altiplano',
  'barenpark',
  'chronicles',
  'codenames',
  'concordia',
  'everdell',
  'pandemic',
  'lisboa',
  'maracaibo',
  'micromacro',
  'mysterium',
  'orleans',
  'santorini',
  'parks',
  'burgundy',
  'jamaica',
  'seasons',
  'suburbia',
  'canvas',
  'essenspiel',
  'smallworld',
  'arkham',
  'horrified',
  'atiwa',
  'woodcraft',
  'witchstone',
  'fasanerie',
  'lacrimosa',
  'hamlet',
  'eleven',
  'kosmos',
  'hanabi',
  'revive',
  'heat',
  'pansisland',
  'applejack',
  'patchwork',
  'troyes',
  'sagrada',
  'mandala',
  'ubongo',
  'citadels',
  'powergrid',
  'unlock',
  'brass',
  'clever',
  'saboteur',
  'agricola',
  'paleo',
  'cloudage',
  'boonlake',
  'dominion',
  'puertorico',
  'loveletter',
  'stoneage',
  'alchemists',
  'caledonia',
  'deception',
  'kingdomino',
  'wavelength',
  'waterdeep',
  'lostcities',
  'sushigo',
  'bloodrage',
  'machikoro',
  'istanbul',
  'anachrony',
  'mombasa',
  'betrayal',
  'imagine',
  'imhotep',
  'bohnanza',
  'celestia',
  'deckscape',
  'fivetribes',
  'century',
  'sonora',
  'targi',
  'arknova',
  'nemesis',
  'barrage',
  'lehavre',
  'obsession',
  'decrypto',
  'caylus',
  'destinies',
  'obscurio',
  'scythe',
  'cantaloop',
  'frosthaven',
  'oathsworn',
  'spicy',
  'flamecraft',
  'carnegie',
  'frostpunk',
  'tiletum',
  'libertalia',
  'wolves',
  'akropolis',
  'verdant',
  'marrakesh',
  'autobahn',
  'sabika',
  'dogpark',
  'evergreen',
  'boop',
  'hitster',
  'splito',
  'pessoa',
  'coral',
  'minecraft',
  'earth',
  'hegemony',
  'distilled',
  'witcher',
  'barcelona',
  'nucleum',
  'pointcity',
  'lorcana',
  'septima',
  'kutnahora',
  'faraway',
  'apiary',
  'evenfall',
  'myisland',
  'plantanubo',
  'tipperary',
  'emerge',
  'daybreak',
  'celtae',
  'myshelfie',
  'coatl',
  'dulce',
  'anunnaki',
  'chomp',
  'kemet',
  'bower',
  'golem',
  'bacon',
  'pakal',
  'bites',
  'lunar',
  'scout',
  'lorenzo',
  'setup',
  'djinn',
  'astra',
  'pickitup',
  'kites',
  'junta',
  'iwari',
  'broadlines',
  'mafia',
  'porto',
]

export const HINTS = [
  'dazzling',
  'noise',
  'amour',
  'artsy',
  'trades',
  'peace',
  'church',
  'physical',
  'canopies',
  'overwhelms',
  'habitats',
  'stacking',
  'chambers',
  'patchy',
  'recruiting',
  'rotates',
  'beaker',
  'pinnacles',
  'blackbear',
  'storylines',
  'misdirect',
  'economics',
  'creature',
  'toosoon?',
  'remake',
  'caribbean',
  'detectives',
  'ghosttale',
  'builder',
  'bluewhite',
  'trail',
  'feldgame',
  'sailing',
  'allyear',
  'building',
  'paints',
  'wonderland',
  'extinction',
  'horror',
  'monstrous',
  'ghana',
  'splitdice',
  'kniziagame',
  'notturkey',
  'classical',
  'settle',
  'soccer',
  'advent',
  'colors',
  'frozen',
  'cars',
  'alwayskids',
  'fruitmade',
  'sewcloth',
  'france',
  'rainbow',
  'circles',
  'tetris',
  'townsman',
  'electrics',
  'riddle',
  'alloy',
  'number',
  'sneaking',
  'farmyard',
  'tribe',
  'airships',
  'waterway',
  'kingdoms',
  'cornfields',
  'courtships',
  'gatherer',
  'laboratory',
  'contracts',
  'forensics',
  'landscapes',
  'alignments',
  'cityscape',
  'expedition',
  'pudding',
  'pillaging',
  'landmarks',
  'gemstone',
  'timespace',
  'company',
  'haunting',
  'clipart',
  'sailing',
  'growbean',
  'jumpship',
  'adventure',
  'sultanates',
  'silkway',
  'desert',
  'dates',
  'shelter',
  'survive',
  'damming',
  'seaport',
  'victorian',
  'clueless',
  'france',
  'exploring',
  'illusion',
  'unrest',
  'mischiefs',
  'settlement',
  'protector',
  'cheat',
  'magicshops',
  'industry',
  'icywinter',
  'venture',
  'plundering',
  'growls',
  'antiquity',
  'nurture',
  'influence',
  'roadways',
  'nasrid',
  'walking',
  'landscape',
  'push',
  'onradio',
  'divide',
  'writer',
  'ocean',
  'overworld',
  'grows',
  'politics',
  'artisanal',
  'sorcery',
  'urbanized',
  'uranium',
  'buildings',
  'legends',
  'witches',
  'silverore',
  'explore',
  'colony',
  'timeless',
  'tropical',
  'skygardens',
  'ruralness',
  'island',
  'climates',
  'tribes',
  'organized',
  'aztec',
  'candy',
  'atlantis',
  'dinos',
  'egypt',
  'nests',
  'rabbi',
  'crisp',
  'slide',
  'trail',
  'night',
  'tents',
  'italian',
  'jumps',
  'magic',
  'stars',
  'homerace',
  'windy',
  'power',
  'tribe',
  'seethrough',
  'crime',
  'floor',
]